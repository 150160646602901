@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

$font-path: "../fonts" !default;
// Body

$body-bg: #F3F4F8;


$text-line-trans:           20px;
$text-line-padding:         7px;
$text-line-padding-hover:   7px;

$font-lg:                   40px !important;

// Colors
$primary:               #B4A2FE !default; // #3060B2
$secondary:             #0F24B5 !default;  //#00FFE1
$color-default:         #071332;
$color-btn:             #fff;
$color-label:           #5D6D7E;
$dark-gray:             #34495E;
$color-danger:          #FF3232;
$color-placeholder:     #ccc;
$color-success:         #28CF5F;
$color-info:            #2AC7E9;
$color-warning:         #EDA826;
$color-disabled:        #ccc;
$color-f1f3f4:          #f1f3f4;
$color-fff:             #FFFFFF;

//Button
$btn-border-width:      2px;

//Radius
$border-radius:         4px;

//Background
$bg-primary:            $primary;
$bg-secondary:          $secondary;
$bg-input:              $color-btn;
$bg-nav-left:           #B4A2FE;
$bg-primary-select:     rgba(157, 133, 255, 0.5);
$bg-list:               #F4F7FB;
$bg-primary-content:    #F4F7FB;
$bg-secondary-content:  #fff;
$bg-B0B3B7 :            #B0B3B7;
$bg-9F84FF :            #9F84FF;
$bg-A0041A :            #A0041A;
$bg-F3F6FB :            #F3F6FB;

// define color of workspace
$wsp_cl1: #0F24B5;
$wsp_cl2: #F93B47;
$wsp_cl3: #5BD262;
$wsp_cl4: #A401BA;
$wsp_cl5: #45650F;
$wsp_cl6: #1B3C01;
$wsp_cl7: #3D17C9;
$wsp_cl8: #22FEA4;
$wsp_cl9: #6F5B18;
$wsp_cl10: #C6B6E9;
$wsp_cl11: #F07AEE;
$wsp_cl12: #336387;
$wsp_cl13: #2D94E4;
$wsp_cl14: #D343E0;
$wsp_cl15: #6B9E4F;
$wsp_cl16: #7DE2DD;

//Space
$distance:              8px;

//Shadow
$shadow:                0 0 1px 0 rgba(10,31,68,0.08), 0 8px 10px 0 rgba(10,31,68,0.10);
$border-input: 1px solid #CED1DA;

$icon-enlive-information: "\e932";
$icon-enlive-setting: "\e931";
$icon-enlive-notication: "\e930";
$icon-enlive-people: "\e906";
$icon-enlive-home: "\e907";
$icon-enlive-camera: "\e900";
$icon-enlive-heart: "\e901";
$icon-enlive-help: "\e902";
$icon-enlive-lock: "\e903";
$icon-enlive-log-out: "\e904";
$icon-enlive-more-option: "\e905";
$icon-enlive-star: "\e908";
$icon-enlive-behance: "\e909";
$icon-enlive-facebook: "\e90a";
$icon-enlive-google: "\e90b";
$icon-enlive-instagram: "\e90c";
$icon-enlive-network: "\e90d";
$icon-enlive-twitter: "\e90e";
$icon-enlive-add: "\e90f";
$icon-enlive-arrow-left: "\e910";
$icon-enlive-arrow-right: "\e911";
$icon-enlive-arrow-top: "\e912";
$icon-enlive-arrow-shape: "\e913";
$icon-enlive-calendar: "\e914";
$icon-enlive-check: "\e915";
$icon-enlive-close: "\e916";
$icon-enlive-cloud: "\e917";
$icon-enlive-data: "\e918";
$icon-enlive-delete: "\e919";
$icon-enlive-download: "\e91a";
$icon-enlive-duplicate: "\e91b";
$icon-enlive-eye: "\e91c";
$icon-enlive-file: "\e91d";
$icon-enlive-filter: "\e91e";
$icon-enlive-heart-stroke: "\e91f";
$icon-enlive-list: "\e920";
$icon-enlive-message: "\e921";
$icon-enlive-mobile: "\e922";
$icon-enlive-money: "\e923";
$icon-enlive-paper: "\e924";
$icon-enlive-picture: "\e925";
$icon-enlive-place: "\e926";
$icon-enlive-play: "\e927";
$icon-enlive-profil: "\e928";
$icon-enlive-relationship: "\e929";
$icon-enlive-search: "\e92a";
$icon-enlive-show: "\e92b";
$icon-enlive-star-empty: "\e92c";
$icon-enlive-time: "\e92d";
$icon-enlive-video: "\e92e";
$icon-enlive-arrow-bottom: "\e92f";
$icon-enlive-arrow-back: "\e933";
$icon-enlive-fil: "\e934";

$map-typography: (
    h1: 27px,
    h2: 23px,
    h3: 19px,
    h4: 16px,
    h5: 14px,
);

@each $element, $size in $map-typography {
    #{$element} {
        font-size: $size;
    }
}

// @mixin button-variant($background, $border, $hover-background: transparent) {
//     color: $color-btn !important;
//     background-color: $background;
//     border: $btn-border-width solid $border !important;
//     @include hover {
//         color: $background !important;
//         background-color: $hover-background !important;
//     }
// }

// @mixin hover {
//     &:hover { @content; }
// }

// $theme-colors: () !default;
// $theme-colors: map-merge(
//     (
//         "primary"   :       $primary,
//         "secondary" :       $secondary,
//         "success"   :       $color-success,
//         "info"      :       $color-info,
//         "warning"   :       $color-warning,
//         "disabled"  :       $color-disabled
//     ),
//     $theme-colors
// );


// @each $color, $value in $theme-colors {
//     .btn-#{$color} {
//         @include button-variant($value, $value);
//     }
//     .color-#{$color} {
//         color: $value;
//     }
//     .bg-#{$color} {
//         background-color: $value;
//     }
// }

@mixin truncate-text($line) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
//GUI Master
// Typography
$font-family-sans-serif:    "Inter";
$font-h1:                   27px;
$font-h2:                   23px;
$font-h3:                   19px;
$font-body:                 16px;
$font-caption:              12px;
$lineheight-h1:             35px;
$lineheight-h2:             30px;
$lineheight-h3:             25px;
$lineheight-body:           24px;
$lineheight-caption:        18px;
$font-weight-body:           300;

//Color
$primary-light00:         #F9FBFC;
$primary-light90:         #646465;
$primary-light80:         #AEB0B0;
$primary-light60:         #E0E2E3;
$primary-light40:         #FDFDFE;
$primary-light20:         #FEFEFE;
$primary-light10:         #FFFFFF;
$primary-midtone00:         #636C83;
$primary-midtone90:         #3B414F;
$primary-midtone80:         #454C5C;
$primary-midtone60:         #596176;
$primary-midtone40:         #C1C4CD;
$primary-midtone20:         #E0E2E6;
$primary-midtone10:         #EFF0F3;
$primary-dark00:         #0A1B48;
$primary-dark90:         #06102B;
$primary-dark80:         #071332;
$primary-dark60:         #091841;
$primary-dark40:         #9DA4B6;
$primary-dark20:         #CED1DA;
$primary-dark10:         #E7E8ED;
$secondary00:         #F3F4F8;
$secondary90:         #929295;
$secondary80:         #AAABAE;
$secondary60:         #DBDCDF;
$secondary40:         #F8FAFB;
$secondary20:         #FBFDFD;
$secondary10:         #FDFEFD;
$accent01-00:         #0F24B5;
$accent01-90:         #09166D;
$accent01-80:         #0B197F;
$accent01-60:         #0E20A3;
$accent01-40:         #9FA7E1;
$accent01-20:         #CFD3F0;
$accent01-10:         #E7E9F8;
$accent02-00:         #F29C1C;
$accent02-90:         #915E11;
$accent02-80:         #A96D14;
$accent02-60:         #DA8C19;
$accent02-40:         #FAD7A4;
$accent02-20:         #FCEBD2;
$accent02-10:         #FEF5E8;
$success-light:       #A6D094;
$success-midtone:     #6AB04C;
$success-dark:        #406A2E;
$warning-light:       #FBBF6A;
$warning-midtone:     #F89406;
$warning-dark:        #AE6804;
$info-light:          #85C1E9;
$info-midtone:        #3498db;
$info-dark:           #1F5B83;
$error-light:         #F1948A;
$error-midtone:       #e74c3c;
$error-dark:          #8B2E24;
//Shadow
$shadow-soft:        0px 2px 2px rgba(10,27,72,0.15);
$shadow-medium:      0px 10px 10px rgba(10,27,72,0.1);
$shadow-high:        0px 12px 25px rgba(10,27,72,0.2);

//Button
$btn-hover:      $accent02-00;
$btn-hover-shadow:   0px 10px 10px rgba(10, 27, 72, 0.1);
$btn-focus:      $accent01-80;
$btn-primary-disabled:      $primary-midtone40;
$btn-primary-bg:      $accent01-00;
$btn-primary-text:      $primary-light00;
$btn-secondary-bg:      $primary-light10;
$btn-secondary-text:      $primary-dark00;
$btn-secondary-disabled:      $primary-dark40;
$btn-secondary-disabled-bg:      $primary-light00;
$btn-secondary-border: $primary-dark20;
$btn-secondary-focus-bg: $secondary40;
$btn-secondary-color-icon: $primary-dark00;
$btn-secondary-color-icon-disable: $primary-dark40;
$btn-border-radius:   8px;
$btn-lr-padding:   12px 32px;
$btn-sm-padding:   8px 24px;
$btn-lr-padding-icon:   12px;
$btn-sm-padding-icon:   8px;
//Checkbox
$checkbox-bg-hover:   $accent02-00;
$checkbox-bg-checked:   $accent01-00;
$checkbox-border: 1.5px solid $primary-dark20;
$checkbox-label-font-size: 16px;
$checkbox-label-font-weight: 300;
$checkbox-label-line-height: 24px;
$checkbox-label-color: $primary-dark80;
$checkbox-border-radius: 3px;
//Radio
$radio-border: 1.5px solid $primary-dark20;
$radio-bg-checked:   $accent01-00;
$radio-border-checked: 1.5px solid $accent01-00;
$radio-bg-hover:   $accent02-00;
$radio-border-hover: 1.5px solid $accent02-00;
$radio-label-font-size: 16px;
$radio-label-line-height: 24px;
$radio-label-font-weight: 300;
$radio-label-color: $primary-dark80;
//Avatar
$avatar-bg: $accent01-90;
$avatar-text-color: $primary-light00;
$avatar-font-weight: 300;
$avatar-fontsize-normal: 16px;
$avatar-lineheight-normal: 24px;
$avatar-fontsize-small: 12px;
$avatar-lineheight-small: 18px;
$avatar-width-height-1: 56px;
$avatar-width-height-2: 48px;
$avatar-width-height-3: 40px;
$avatar-width-height-4: 32px;
$avatar-width-height-5: 24px;
//Tabs
$tab-fontsize: 16px;
$tab-lineheight: 24px;
$tab-fontweight: 300;
$tab-text-color: $primary-dark40;
$tab-text-color-active: $accent01-60;
//Sorting
$sorting-border: 1px solid $primary-dark20;
$sorting-borderradius: 8px;
$sorting-bg: $primary-light10;
$sorting-width: 208px;
$sorting-height: 32px;
$sorting-padding: 8px 13px 8px 40px;
$sorting-fontsize: 12px;
$sorting-lineheight: 18px;
$sorting-focus-border: 1px solid $accent01-60;
$sorting-focus-bg: $accent01-20;
$sorting-option-bg: $primary-light10;
$sorting-option-borderradius: 8px;
$sorting-item-border-radius:      8px;
$sorting-item-border:   1px solid $primary-dark20;
$sorting-item-padding:   3px 16px;
$sorting-item-fontsize:   12px;
$sorting-item-lineheight:  18px;
$sorting-item-hover-color:   $accent01-20;
//Input
$input-width: 100%;
$input-height: 40px;
$input-padding: 8px;
$input-back-ground: $primary-light10;
$input-borderradius: 8px;
$input-border: 1px solid $primary-dark20;
$input-text-color: $primary-dark80;
$input-placeholder-color: $primary-light90;
$input-text-fontsize: 16px;
$input-text-lineheight: 24px;
$input-checked-border-color: $accent01-60;
$input-hover-border-color: $accent01-60;
$input-label-color: $primary-light90;
$input-label-fontsize: 12px;
$input-label-lineheight: 18px;
$input-error-border-color: $error-midtone;
$input-error-text-color: $error-midtone;
$input-error-text-fontweight: 700;
$input-error-text-fontsize: 12px;
$input-error-text-lineheight: 18px;
$input-success-border-color: $success-midtone;
$input-success-icon-color: $success-midtone;
$input-warning-border-color: $warning-midtone;
$input-warning-text-color: $warning-midtone;
$input-disabled-bg: $primary-midtone40;
$input-disabled-text-color: $primary-light90;
//TextArea
$textarea-width:          100%;
$textarea-height:          219px;
$textarea-padding:          8px 11.5px;
$textarea-border:          1px solid $primary-dark20;
$textarea-border-error:          $error-midtone;
$textarea-border-warning:          $warning-midtone;
$textarea-border-info:          $info-midtone;
$textarea-borderradius:          8px;
$textarea-text-placeholder:          $primary-light90;
$textarea-border-active:       1px solid $accent01-60;
$textarea-text-color:          $primary-dark80;
$textarea-text-fontsize:          16px;
$textarea-text-lineheight:          24px;
$textarea-feedback-fontsize:       12px;
$textarea-feedback-lineheight:       18px;
$textarea-feedback-fontweight:       700;
//DataDropdown
$datadropdown-width:      100%;
$datadropdown-height:      284px;
$datadropdown-bg:      $primary-light10;
$datadropdown-color:      $primary-dark80;
$datadropdown-secondary-bg:      $primary-midtone40;
$datadropdown-secondary-color:      $primary-light90;
$datadropdown-item-border-radius:      8px;
$datadropdown-item-border:   1px solid $primary-dark20;
$datadropdown-item-padding:   13px 21px;
$datadropdown-item-hover-color:   $accent02-40;
$datadropdown-active-border:   1px solid $accent01-60;
$datadropdown-active-bg:   $accent01-20;
//DataUpload
$dataupload-width:      100%;
$dataupload-height:      96px;
$dataupload-border:      1px dashed $primary-dark20;
$dataupload-border-radius:      8px;
$dataupload-bg:      $primary-light10;
$dataupload-padding:      18px 16px;
$dataupload-title-fontsize:      16px;
$dataupload-title-lineheight:      24px;
$dataupload-title-text-color:      $primary-dark80;
$dataupload-content-fontsize:      12px;
$dataupload-content-lineheight:      18px;
$dataupload-content-text-color:      $primary-light90;
$dataupload-filename-text-color:      #000;
$dataupload-filename-font-size:     12px;
$dataupload-filename-line-height:      18px;
//ProjectCardGrid
$project-card-grid-width:     100%;
$project-card-grid-height:     298px;
$project-card-grid-bg:     $primary-light10;
$project-card-grid-border-radius:     8px;
$project-card-grid-left-width:     212px;
$project-card-grid-right-padding:     24px 0 18px 24px;
$project-card-grid-type-bg:   $accent01-40;
$project-card-grid-type-padding:   1.5px 8px;
$project-card-grid-type-border-radius:   6px;
$project-card-grid-type-text-color:   $primary-light00;
$project-card-grid-type-text-font-size:   12px;
$project-card-grid-type-text-font-weight:   300;
$project-card-grid-type-text-line-height:  18px;
$project-card-grid-caption-first-font-size:  12px;
$project-card-grid-caption-first-line-height:  18px;
$project-card-grid-caption-first-font-weight:  300;
$project-card-grid-caption-first-text-color:  $primary-light90;
$project-card-grid-caption-second-font-size:  16px;
$project-card-grid-caption-second-line-height:  24px;
$project-card-grid-caption-second-font-weight:  300;
$project-card-grid-caption-second-text-color:  $primary-dark80;
$project-card-grid-member-padding:  16px 13px 18px 0;
//ProjectCardList
$project-card-list-width:     1154px;
$project-card-list-height:     128px;
$project-card-list-padding:     28px 0 28px 24px;
$project-card-list-box-shadow:   inset 0px -1px 0px #CED1DA;
$project-card-list-image-width:     90px;
//Comment
$comment-post-avatar-bg:      $info-light;
$comment-post-avatar-width:      496px;
$comment-post-avatar-border:      1px solid $primary-dark20;
$comment-post-avatar-padding:      24px;
$comment-post-avatar-font-weight:     700;
$comment-post-avatar-text-color:     $primary-dark80;
$comment-post-avatar-checkbox-border-color:     $primary-dark20;
$comment-post-avatar-checkbox-border-checked:     $success-midtone;
$comment-post-avatar-checkbox-text-color:     $primary-light90;
$comment-post-avatar-checkbox-text-font-weight:     300;
$comment-post-avatar-checkbox-text-font-size:     12px;
$comment-post-avatar-checkbox-text-line-height:     18px;
$comment-user-text-font-size: 16px;
$comment-user-text-line-height: 24px;
$comment-user-text-font-weight: bold;
$comment-date-font-size: 16px;
$comment-date-line-height: 24px;
$comment-date-font-weight: 300;
$comment-content-text-color: #000;
$comment-content-font-size: 16px;
$comment-content-line-height: 24px;
$comment-content-font-weight: 300;
$comment-action-hover: $accent01-00;
