@import "variables";
@import "fonts";
@import "common";
@import "page";
@import "responsive";
@import "../components/new-project/style.scss";
* {
	box-sizing: border-box;
  }
iframe {
	display: none !important;
}
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}
html,body {
	background-color: $body-bg;
	color: $color-default;
	font-family: $font-family-sans-serif;
	font-size: $font-body;
	line-height: $lineheight-body;
	font-weight: $font-weight-body;
}
a {
	cursor: pointer;
}
//GUI Master
html,body,#root{
	height: 100%;
}
.cursor-pointer{
	cursor: pointer;
}
a:hover{
	text-decoration: none !important;
}
.bold{
	font-weight: 700;
}
.w-100 {
	width: 100%;
}
.h-100 {
	height: 100%;
}
.wrap-menu{
	width: 16.666667%;
	position: fixed;
	.menuItem{
		display: none;
	}
	.show{
		display: block;
	}
}
.gui-menu {
	background-color: #4E73DF;
	color: $color-fff;
}

.color {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
span{
	font-family: $font-family-sans-serif!important;
}
h1 {
	font-weight: 700 !important;
	font-size: $font-h1 !important;
	line-height: $lineheight-h1 !important;
	margin: 0;
}

h2 {
	font-weight: 700 !important;
	font-size: $font-h2 !important;
	line-height: $lineheight-h2 !important;
	margin: 0;
}

h3 {
	font-weight: 700 !important;
	font-size: $font-h3 !important;
	line-height: $lineheight-h3 !important;
	margin: 0;
}
small{
	font-weight: 300;
	font-size: $font-caption !important;
	line-height: $lineheight-caption !important;
}
.text-uppercase {
	text-transform: uppercase;
}
/* Text */
.text-negatif {
	color: $primary-light00 !important;
}

.text-light {
	color: $primary-dark40 !important;
}

.text-medium {
	color: $primary-light90 !important;
}

.text-dark {
	color: $primary-dark80 !important;
}

.text-headline {
	color: $primary-dark00 !important;
}
.title-wsp {
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 350px;

}
.text-link01 {
	color: $accent01-00 !important;
}

.text-link02 {
	color: $accent02-00 !important;
}

/* Background */
.bgc-primary {
	background: $primary-light00;
}

.bgc-secondary {
	background: $secondary00;
}

.bgc-midtone {
	background: $primary-midtone00;
}

.bgc-dark {
	background: $primary-dark00;
}

/* Shadow */
.shadow-soft{
	box-shadow: $shadow-soft !important;
}
.shadow-medium{
	box-shadow: $shadow-medium !important;
}
.shadow-high{
	box-shadow: $shadow-high !important;
}

// Button
.btn{
	white-space: nowrap;
	border-radius: $btn-border-radius !important;
	box-shadow: none !important;
	&:hover{
		box-shadow: $btn-hover-shadow !important;
	}
}
.btn-pri{
	background: $btn-primary-bg !important;
	&:hover{
		background: $btn-hover!important;
	}
	&:focus{
		background: $btn-focus!important;
	}
	&:disabled{
		background: $btn-primary-disabled!important;
	}
	span{
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: 300;
		color: $btn-primary-text;
	}
}
.btn-pri.text-medium{
	span{
		color: $primary-light90;
	}
}
.btn-sec{
	background: $btn-secondary-bg !important;
	border:1px solid $btn-secondary-border !important;
	&:focus{
		background: $btn-secondary-focus-bg!important;
	}
	&:disabled{
		background: $btn-secondary-disabled-bg!important;
		border:1px solid $btn-secondary-disabled !important;
		svg{
			color: $btn-secondary-color-icon-disable !important;
		}
	}
	span{
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: 300;
		color: $btn-secondary-text;
	}
	svg{
		color: $btn-secondary-color-icon !important;
	}
}
.btn-lr{
	padding: $btn-lr-padding !important;
}
.btn-lr-icon{
	padding: $btn-lr-padding-icon !important;
}
.btn-sm{
	padding: $btn-sm-padding !important;
}
.btn-sm-icon{
	padding: $btn-sm-padding-icon !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: $checkbox-bg-checked !important;
}
.btn-circle{
	border-radius: 50% !important;
}

//Radio
.radioCustom{
	&:checked {
		position: absolute;
		left: -9999px;
		+ {
			label {
				position: relative;
				padding-left: 37px;
				cursor: pointer;
				display: inline-block;
				font-size: $radio-label-font-size;
				line-height: $radio-label-line-height;
				font-weight: $radio-label-font-weight;
				color: $radio-label-color;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 18px;
					height: 18px;
					border: $radio-border-checked;
					border-radius: 100%;
					background: #fff;
				}
				&:after {
					content: '';
					width: 12px;
					height: 12px;
					background: $radio-bg-checked;
					position: absolute;
					top: 6px;
					left: 3px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
					opacity: 1;
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}
		}
	}
	&:not(:checked) {
		position: absolute;
		left: -9999px;
		+ {
			label {
				position: relative;
				padding-left: 37px;
				cursor: pointer;
				display: inline-block;
				font-size: $radio-label-font-size;
				line-height: $radio-label-line-height;
				font-weight: $radio-label-font-weight;
				color: $radio-label-color;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 18px;
					height: 18px;
					border: $radio-border;
					border-radius: 100%;
					background: #fff;
				}
				&:after {
					content: '';
					width: 12px;
					height: 12px;
					background: #F87DA9;
					position: absolute;
					top: 4px;
					left: 4px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
					opacity: 0;
					-webkit-transform: scale(0);
					transform: scale(0);
				}
			}
		}
		&:hover{
			+ {
				label {
					position: relative;
					padding-left: 37px;
					cursor: pointer;
					display: inline-block;
					font-size: $radio-label-font-size;
					line-height: $radio-label-line-height;
					font-weight: $radio-label-font-weight;
					color: $radio-label-color;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 18px;
						height: 18px;
						border: $radio-border-hover;
						border-radius: 100%;
						background: $radio-bg-hover;
					}
					&:after {
						content: '';
						width: 12px;
						height: 12px;
						background: #F87DA9;
						position: absolute;
						top: 4px;
						left: 4px;
						border-radius: 100%;
						-webkit-transition: all 0.2s ease;
						transition: all 0.2s ease;
						opacity: 0;
						-webkit-transform: scale(0);
						transform: scale(0);
					}
				}
			}
		}
	}
}
.MuiSvgIcon-root{
	color: $primary-dark20;
}
.Mui-checked .MuiSvgIcon-root{
	color: $radio-bg-checked;
}
//CheckBox Custom
.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
	  position: relative;
	  cursor: pointer;
	  padding: 0;
	}

	// Box.
	& + label:before {
	  content: '';
	  margin-right: 11px;
		margin-top: 1px;
	  display: inline-block;
	  vertical-align: bottom;
	  width: 18px;
	  height: 18px;
	  background: white;
	  border: $checkbox-border;
	  border-radius: $checkbox-border-radius;
	}
	& + label:before {
	  content: '';
	  margin-right: 11px;
		margin-top: 1px;
	  display: inline-block;
	  vertical-align: bottom;
	  width: 18px;
	  height: 18px;
	  background: white;
	  border: $checkbox-border;
	//   border-radius: 20px;
	}

	// Box hover
	&:hover + label:before {
	  background: $checkbox-bg-hover;
	  border: $checkbox-bg-hover;
	}


	// Box checked
	&:checked + label:before {
	  background: $checkbox-bg-checked;
	  border: $checkbox-bg-checked;
	  border-radius: $checkbox-border-radius;
	}

	// Disabled state label.
	&:disabled + label {
	  cursor: auto;
	  color: $checkbox-label-color;
	  font-size: $checkbox-label-font-size;
	  font-weight: $checkbox-label-font-weight;
	  line-height: $checkbox-label-line-height;
	}

	// Disabled box.
	&:disabled + label:before {
	  box-shadow: none;
	  background: #ddd;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
	  content: "";
	  background: url("http://localhost:3000/svg/check-white.svg") no-repeat;
	  left: 1px;
	  top: 9px;
	  width: 16px;
	  height: 12px;
	  position: absolute;
	  display: inline-block;
	}
}

.checkBoxCircle {
	width: 35px;
    height: 35px;
    border-radius: 50%;
	position: relative;
	cursor: pointer;
}

.ischecked {
	background: url("http://localhost:3000/svg/check-white.svg") no-repeat;
	left: 9px;
	top: 10px;
	width: 16px;
	height: 12px;
	position: absolute;
	display: inline-block;
}



//Tab
.MuiTab-wrapper{
	text-transform: capitalize;
	font-size: $tab-fontsize;
	font-weight: $tab-fontweight;
	line-height: $tab-lineheight;
}
.MuiTab-textColorPrimary{
	color: $tab-text-color !important;
}
.MuiTab-textColorPrimary.Mui-selected {
	color: $tab-text-color-active !important;
}
.PrivateTabIndicator-colorPrimary-16 {
	background-color: $tab-text-color-active !important;
}
.MuiTab-labelIcon{
	.MuiTab-wrapper{
		flex-direction: row;
		svg{
			margin-bottom: 0 !important;
			margin-right: 13px;
		}
	}
}
//Avatar
.avatar {
	cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50% !important;
    justify-content: center;
    font-family: $font-family-sans-serif;
	background-color: $avatar-bg;
	color: $avatar-text-color;
	font-weight: $avatar-font-weight;
	object-fit: cover;
	text-transform: uppercase;
}
.avatar-1{
	width: $avatar-width-height-1;
	height: $avatar-width-height-1;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-2{
	width: $avatar-width-height-2;
	height: $avatar-width-height-2;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-3{
	width: $avatar-width-height-3;
	height: $avatar-width-height-3;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-4{
	width: $avatar-width-height-4;
	height: $avatar-width-height-4;
	font-size: $avatar-fontsize-small;
	line-height: $avatar-lineheight-small;
}
.avatar-5{
	width: $avatar-width-height-5;
	height: $avatar-width-height-5;
	font-size: $avatar-fontsize-small;
	line-height: $avatar-lineheight-small;
}
//Sorting

.wrap-sorting {
	width: $sorting-width;
	height: $sorting-height;
	.wrapInput{
		position: relative;
		.sorting{
			border: $sorting-border;
			border-radius: $sorting-borderradius;
			background: $sorting-bg;
			width: $sorting-width;
			height: $sorting-height;
			font-size: $sorting-fontsize;
			line-height: $sorting-lineheight;
			box-sizing: border-box;
			padding: $sorting-padding;
			pointer-events: none;
		}
		.sorting.active {
			border: $sorting-focus-border;
			background: $sorting-focus-bg;
			outline: 0;
		}
		.icon-left{
			left: -155px;
		}
		img{
			margin: auto;
			position: absolute;
			top: 0; bottom: 0; right: 13px;
		}
	}
	ul{
		width: $sorting-width !important;
		background: $sorting-bg;
		border: $sorting-item-border;
		border-radius: $sorting-item-border-radius;
		padding: 16px 0;
		position: absolute;
		z-index: 999;
		li{
			display: flex;
			align-items: center;
			list-style: none;
			padding: $sorting-item-padding;
			&:hover{
				background: $sorting-item-hover-color;
				cursor: pointer;
			}
			font-size: $sorting-item-fontsize;
			line-height: $sorting-item-lineheight;
		}
	}
	ul.show{
		display: block;
	}
}
//Input
.input{
	width: $input-width ;
	// height: $input-height ;
	// max-height: $input-height ;
	padding: 8px;
	.MuiInputBase-root{
		background:$input-back-ground;
		height: $input-height ;
		padding: $input-padding ;
		// border: $input-border ;
		border-radius: $input-borderradius ;
	}
	.Mui-focused{
		border-color: $input-checked-border-color;
		border-width: 1px;
	}
	.Mui-error{
		border-color: $input-error-border-color;
	}
	.MuiFormHelperText-root.Mui-error {
		color: $input-error-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
	.MuiInputBase-root.Mui-disabled{
		background: $input-disabled-bg;
		input{
			color:$input-disabled-text-color;
		}
		.MuiOutlinedInput-notchedOutline{
			border-color: #CED1DA !important;
		}
	}
	input{
		padding: 0;
		height: $input-height;
		color: $input-text-color;
		font-size: $input-text-fontsize;
		line-height: $input-text-lineheight;

	}
	input::placeholder{
		color: $input-placeholder-color;
	}
	.MuiOutlinedInput-notchedOutline {
		border: 0;
	}
	.MuiInputAdornment-positionStart {
		margin-right: 6px;
	}
	.MuiIconButton-root{
		padding: 0;
	}
	&.muiSelect{
		.MuiSelect-selectMenu{
			padding: 8.5px !important;
		}
	}

	img.i-count {
		cursor: pointer;
	}
}
.muiSelect{
	.MuiInputBase-root{
		&:hover{
			background-color: #fff;
		}
	}
	.MuiFilledInput-root.Mui-focused{
		background-color: #fff;
	}
}
.typePassword{
	&:hover{
		border-color: $input-hover-border-color;
	}
	&:focus-within{
		border-color: $input-hover-border-color;
	}
	background: $input-back-ground;
	input{
		color:$input-text-color;
	}
}
label + .MuiInput-formControl {
    margin-top: 0 !important;
}

.inputSuccess{
	.MuiInputBase-root{
		border-color: $input-success-border-color ;
	}
	.MuiInputAdornment-positionEnd {
		color:$input-success-icon-color;
	}
}
.inputWarning{
	.MuiInputBase-root{
		border-color: $input-warning-border-color ;
	}
	.MuiFormHelperText-contained {
		color: $input-warning-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
}
.inputError{
	.MuiInputBase-root{
		border-color: $input-error-border-color ;
	}
	.MuiFormHelperText-contained {
		color: $input-error-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
}
.inputLabel{
	margin-bottom: 8px;
	color: $input-label-color;
	font-size: $input-label-fontsize;
	line-height: $input-label-lineheight;
	// text-transform: capitalize;
	img{
		width: 15px;
		height: 15px;
		margin-left: 4.5px;
	}
}
.inputLabelCount{
	width: $textarea-width;
	display: flex;
	justify-content: space-between;
	span{
		color: $input-label-color;
		font-size: $input-label-fontsize;
		line-height: $input-label-lineheight;
	}
	.inputLabel img {
		margin-left: 9.5px;
	}
}
//TextArea
.textarea{
	width: $textarea-width;
	// height: $textarea-height;
	border: $textarea-border;
	border-radius: $textarea-borderradius;
	color: $textarea-text-color;
	padding: $textarea-padding;
	font-size: $textarea-text-fontsize;
	line-height: $textarea-text-lineheight;
	&::placeholder{
		color: $textarea-text-placeholder;
	}
	&:focus-visible {
		border: $textarea-border-active;
		outline: 0;
	}
}
.textarea.error{
	border-color: $textarea-border-error;
}
.textarea.warning{
	border-color: $textarea-border-warning;
}
.textarea.info{
	border-color: $textarea-border-info;
}
.textarea-feedback{
	font-size: $textarea-feedback-fontsize;
	font-weight: $textarea-feedback-fontweight;
	line-height: $textarea-feedback-lineheight;
	margin-top: -4px;
	margin-bottom: 0;
}
.textarea-feedback.error{
	color: $textarea-border-error;
}
.textarea-feedback.warning{
	color: $textarea-border-warning;
}
.textarea-feedback.info{
	color: $textarea-border-info;
}
.clearfix::after {
	content: "";
	clear: both;
	display: table;
  }
//Select
.dataDropdown {
	width: $datadropdown-width;
	position: relative;
	.wrapInput{
		cursor: pointer;
		.input{
			border-radius: $input-borderradius ;
			border: $input-border ;
			padding: $input-padding !important;
			background: $datadropdown-bg;
			pointer-events: none;
			color:  $datadropdown-color;
			&::placeholder{
				color:  $datadropdown-color;
			}
		}
		.input.active {
			border : $datadropdown-active-border;
			background:$datadropdown-active-bg;
			outline: 0;
		}
		img{
			margin: auto;
			position: absolute;
			top: 0; bottom: 0; right: 17px;
		}
	}
	ul{
		width: $datadropdown-width;
		background: $datadropdown-bg;
		border: $datadropdown-item-border;
		border-radius: $datadropdown-item-border-radius;
		padding: 16px 0;
		display: none;
		position: absolute;
		z-index: 999;
		max-height: $datadropdown-height;
		overflow: auto;
		color: $datadropdown-color;
		li{
			display: flex;
			align-items: center;
			list-style: none;
			padding: $datadropdown-item-padding;
			&:hover{
				background: $datadropdown-item-hover-color;
				cursor: pointer;
			}
			font-size: $font-body;
			line-height: $lineheight-body;
		}
		hr{
			margin: 0;
		}
		.icon-left{
			margin-right: 13px;
		}
		.icon-right{
			margin-left: 13px;
		}
	}
	ul.show{
		display: block;
	}
}
.dataDropdown.secondary{
	.wrapInput{
		.input{
			background: $datadropdown-secondary-bg;
			color: $datadropdown-secondary-color;
			&::placeholder{
				color: $datadropdown-secondary-color;
			}
		}
	}
	ul{
		color: $datadropdown-secondary-color;
	}
}
//DataUpload
.wrap-dataUpload{
	.dataUpload{
		input[type="file"]{
			display: none;
		}
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: $dataupload-width;
		height: $dataupload-height;
		border-radius: $dataupload-border-radius;
		border: $dataupload-border;
		background: $dataupload-bg;
		padding: $dataupload-padding;
		p{
			margin: 0;
			font-size: $dataupload-content-fontsize;
			line-height: $dataupload-content-lineheight;
			color: $dataupload-content-text-color;
		}
		.title{
			font-size: $dataupload-title-fontsize;
			line-height: $dataupload-title-lineheight;
			color: $dataupload-title-text-color;
		}
	}
	.MuiCircularProgress-circleIndeterminate {
		stroke-dasharray: 25px, 200px;
	}
	.dataUploadFile{
		width: $dataupload-width;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 7px;
		.fileName{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: $dataupload-filename-text-color;
			font-size: $dataupload-filename-font-size;
			line-height: $dataupload-filename-line-height;
			label{
				margin-left: 9px;
			}
		}
		.MuiLinearProgress-root{
			width: 108px;
		}
		.loading{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.iconRefresh{
				margin-right: 17px;
			}
		}
	}
}
//ProjectCardGrid
.projectCardGrid{
	width: $project-card-grid-width;
	height: $project-card-grid-height;
	border-radius: $project-card-grid-border-radius;
	display: flex;
	background: $project-card-grid-bg;
	.left{
		overflow: hidden;
		width: $project-card-grid-left-width;
		border-bottom-left-radius: $project-card-grid-border-radius;
		border-top-left-radius: $project-card-grid-border-radius;
	}
	.right{
		width: calc(100% - 212px);
		padding: $project-card-grid-right-padding;
		.headline{
			display: flex;
			justify-content: space-between;
			.title{
				flex: 0 0 90%;
			}
			.icon{
				flex: 0 0 10%;
				position: relative;
				img{
					cursor: pointer;
				}
				.setting{
					position: absolute;
					top: 5px;
					left: -300px;
					z-index: 999;
					width: 294px;
					padding: 24px 24px 32px 18px;
					background: #FFFFFF;
					box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
					border-radius: 8px 0px 8px 8px;
					.head{
						display: flex;
						justify-content: space-between;
						margin-bottom: 21px;
						label{
							font-weight: 700;
						}
						img{
							cursor: pointer;
						}
					}
					ul{
						list-style: none;
						margin: 0;
						padding: 0;
						li{
							margin-bottom: 16px;
							&:nth-last-child(1){
								margin-bottom: 0;
							}
							a{
								color: $primary-dark80;
							}
							img{
								margin-right: 10px;
							}
						}
					}
				}
				.show{
					display: block;
				}
			}
		}
		.type{
				padding: $project-card-grid-type-padding;
				border-radius: $project-card-grid-type-border-radius;
				background: $project-card-grid-type-bg;
				color: $project-card-grid-type-text-color;
				font-size: $project-card-grid-type-text-font-size;
				font-weight: $project-card-grid-type-text-font-weight;
				line-height: $project-card-grid-type-text-line-height;
		}
		.caption{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.item{
				flex: 0 0 50%;
				display: flex;
				.icon{
					margin-right: 10px;
				}
				.title{
					label{
						font-size: $project-card-grid-caption-first-font-size;
						line-height: $project-card-grid-caption-first-line-height;
						font-weight: $project-card-grid-caption-first-font-weight;
						color: $project-card-grid-caption-first-text-color;
					}
					p{
						margin-bottom: 0;
						font-size: $project-card-grid-caption-second-font-size;
						line-height: $project-card-grid-caption-second-line-height;
						font-weight: $project-card-grid-caption-second-font-weight;
						color: $project-card-grid-caption-second-text-color;
						white-space: nowrap;
					}
				}
			}
		}
		hr{
			border-color: $primary-dark20;
			margin: 0;
		}
		.member{
			display: flex;
			justify-content: space-between;
			padding: $project-card-grid-member-padding;
			.avatarList{
				display: flex;
				justify-content: flex-start;
				.avatar{
					margin-right: 8px;
				}
			}
		}
	}
}
//ProjectCardList
.projectCardList{
	.icon{
		// margin: auto;
		// flex: 0 0 10%;
		position: relative;
		img{
			cursor: pointer;
		}
		.setting{
			position: absolute;
			top: 5px;
			left: -300px;
			z-index: 999;
			width: 294px;
			padding: 24px 24px 32px 18px;
			background: #FFFFFF;
			box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
			border-radius: 8px 0px 8px 8px;
			.head{
				display: flex;
				justify-content: space-between;
				margin-bottom: 21px;
				label{
					font-weight: 700;
				}
				img{
					cursor: pointer;
				}
			}
			ul{
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					margin-bottom: 16px;
					&:nth-last-child(1){
						margin-bottom: 0;
					}
					a{
						color: $primary-dark80;
					}
					img{
						margin-right: 10px;
					}
				}
			}
		}
	}
	.tag{
		padding: $project-card-grid-type-padding;
		border-radius: $project-card-grid-type-border-radius;
		background: $project-card-grid-type-bg;
		color: $project-card-grid-type-text-color;
		font-size: $project-card-grid-type-text-font-size;
		font-weight: $project-card-grid-type-text-font-weight;
		line-height: $project-card-grid-type-text-line-height;
	}
}

//Comment
.comments{
	width: $comment-post-avatar-width;
	// border: $comment-post-avatar-border;
	padding: $comment-post-avatar-padding;
	.post{
		display: flex;
		justify-content: flex-start;
		.avatar{
			background: $comment-post-avatar-bg;
			font-weight: $comment-post-avatar-font-weight;
			color: $comment-post-avatar-text-color;
			margin-right: 13px;
		}
		.MuiCheckbox-root {
			color: $comment-post-avatar-checkbox-border-color;
		}
		.MuiTypography-body1 {
			color: $comment-post-avatar-checkbox-text-color;
			font-weight: $comment-post-avatar-checkbox-text-font-weight;
			font-size: $comment-post-avatar-checkbox-text-font-size;
			line-height: $comment-post-avatar-checkbox-text-line-height;
		}
		.MuiCheckbox-colorSecondary.Mui-checked {
			color: $comment-post-avatar-checkbox-border-checked !important;
		}
	}
	.comment{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.user{
			display: flex;
			justify-content: space-between;
			align-items: center;
			label{
				font-size: $comment-user-text-font-size;
				line-height: $comment-user-text-line-height;
				font-weight: $comment-user-text-font-weight;
			}
		}
		.date{
			font-size: $comment-date-font-size;
			line-height: $comment-date-line-height;
			font-weight: $comment-date-font-weight;
		}
	}
	.content{
		color: $comment-content-text-color;
		font-size: $comment-content-font-size;
		line-height: $comment-content-line-height;
		font-weight: $comment-content-font-weight;
	}
	.action{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		a{
			text-decoration: underline;
			cursor: pointer;
			&:hover{
				color: $comment-action-hover;
			}
		}
	}
	.reply{
		display: none;
		overflow: auto;
	}
	.input::placeholder{
		color: #071332 !important;
	}
	.show{
		display: block;
	}
	.hide{
		display: none;
	}
	.listButton{
		float: right;
	}
}
.PrivateRadioButtonIcon-checked-155 svg{
	color: $accent01-00;
}
.MuiTabs-indicator{
	height: 3px !important;
}
//Table
.MuiTableRow-head{
	background: $primary-light00 !important;
}
.MuiTableCell-head{
	padding: 7px 24px !important;
}
// .MuiTableCell-body{
// 	padding: 16px 24px !important;
// }
.MuiTableCell-root{
	border-bottom: 1px solid #E0E2E6;
}
.projectTable{
	// overflow: inherit !important;
	.MuiTableCell-head{
		padding: 4px 24px !important;
	}
	.MuiTableCell-root{
		font-family: $font-family-sans-serif;
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: $font-weight-body;
	}
}
//CheckBox
.market{
	.MuiFormControlLabel-root{
		width: 100%;
	}
	.MuiFormControlLabel-label{
		font-weight: 300 !important;
		font-size: 12px !important;
		line-height: 18px !important;
		color: #071332;
	}
}
//MarketSlide
.marketPlaceSlide{
	.wrap-slide{
		width: 802px;
		margin: auto;
	}
	.swiper {
		width: 100%;
		height: 100%;
	}
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper {
		width: 100%;
		height: 300px;

	}

	.swiper-slide {
		background-size: cover;
		background-position: center;
	}

	.mySwiper2 {
		height: 432px;
		border-radius: 8px;
		margin-left: auto;
		margin-right: auto;
	}

	.mySwiper {
		height: 20%;
		box-sizing: border-box;
		width: calc(100% - 94px);
		margin-left: 0;
	}

	.mySwiper .swiper-slide {
		width: 94px;
		height: 48px;
		border-radius: 4px;
		overflow: hidden;
	}

	.mySwiper .swiper-slide-thumb-active {
		opacity: 1;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

}
//projectMenuRight
.projectMenuRight{
	.MuiTab-wrapper{
		font-weight: 300;
		font-size: 12px !important;
		line-height: 18px !important;
	}
	.MuiTypography-body1{
		font-weight: 300;
		font-size: 12px !important;
		line-height: 18px !important;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
}
.projectMenuRightComment{
	.Mui-checked{
		.MuiSvgIcon-root{
			color: $success-midtone;
		}
	}

}
.show-alert-content {
	position: absolute;
    right: 30px;
    width: auto;
    z-index: 9;
    bottom: 20px;
	.alert {
		padding: 0.25rem 1.25rem;
	}
}
.transform-overflow {
	.react-transform-wrapper {
		overflow: unset !important;
	}
}

.tab-collapse {
	.image-collapse {
		width: 137px;
		padding: 1px;
    	margin: 1px 1px 5px 1px;
	}

	.image-collapse:hover {
		border: 1px solid #cfd1db;
	}
}

.tab-collapse {
	.image-collapse.active {
		border: 1px solid #0E20A3;
	}
}

.mr-right-10 {
	margin-right: 10px !important;
}
.d-none{
	display: none;
}
.show-icon-brief-photo::after {
	content: url('../assets/svg/brief-blue.svg');
	position: absolute;
    bottom: 0px;
    right: 0px;
}

.error-informations-input {
	color: #e74c3c;
    font-weight: 700;
    line-height: 18px;
    font-size: 12px;
    margin: 2px 0 0 0;
}
.lineWithText{
	width: 100%;
    height: 15px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-align: left;
	span{
		font-size: 14px;
		background-color: white;
		padding-right: 10px;
	}
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-24 {
	font-size: 24px;
}

.container-buttom {
	display: flex;
    margin-left: 200px;

	.b-connect {
		margin: 35px 10px 0px 0px !important;
	}
}

.page-resend-mail {
	.title-resend-mail {
		color: #0A1B48;
		font-size: 19px;
		font-weight: bold;
		line-height: 25px;
	}
	
}

.page-sign-in {
	input[type="text"], textarea {
		background-color : #fff; 
	}
	
	.input .MuiInputBase-root {
		padding: 0 !important;
	}
	
	.input .MuiInputBase-root .MuiInputBase-input.MuiInput-input {
		border-radius: 8px;
		padding-left: 8px;
		padding-right: 8px;
		height: 38px;
	
	}
}


