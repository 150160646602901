@import "variables";

$bg-primary-enable: $accent01-00;
$bg-primary-hover: $accent02-00;
$bg-primary-focus: $accent01-60;
$bg-primary-press: $accent01-80;
$bg-primary-disabled: $primary-midtone40;

$color-primary-enable: $primary-light00;
$color-primary-disabled: $primary-light90;

$bg-second-enable: $primary-light10;
$bg-second-hover: $primary-light10;
$bg-second-focus: $primary-light10;
$bg-second-press: $secondary40;
$bg-second-disabled: $primary-light10;
$border-input: 1px solid #CED1DA;

@mixin button-variant($type, $background) {
    background-color: $background !important;
    border-radius: 8px;
    padding: 12px 32px;

    @if $type == 'primary' {
        color: $primary-light00 !important;
        border: none;
    }  @else if $type == 'secondary' {
        color: $primary-dark00 !important;
        border: $border-input !important;
    } @else if $type == 'disabled' {
        color: $color-primary-disabled !important;
        border: $border-input !important;
    } @else {
        color: $primary-light00 !important;
        border: none;
    }

    @include hover {
        @if $type == 'primary' {
            background-color: $bg-primary-hover !important;
            box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1) !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-hover !important;
            box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1) !important;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
    @include focus {
        @if $type == 'primary' {
            background-color: $bg-primary-focus !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-focus !important;
            border: 1px solid #9DA4B6;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
    @include press {
        @if $type == 'primary' {
            background-color: $bg-primary-press !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-press !important;
            border: 1px solid #9DA4B6;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
}

@mixin hover {
    &:hover { @content; }
}
@mixin focus {
    &:focus { @content; }
}
@mixin press {
    &:active { @content; }
}

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "primary"   :       $bg-primary-enable,
        "secondary" :       $bg-second-enable,
        "success"   :       $success-midtone,
        "info"      :       $info-midtone,
        "warning"   :       $warning-midtone,
        "error"     :       $error-midtone,
        "disabled"  :       $bg-primary-disabled
    ),
    $theme-colors
);

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($color, $value);
    }
    .color-#{$color} {
        color: $value;
    }
    .bg-#{$color} {
        background-color: $value !important;
    }
}

$colors: ("wsp_cl1": "#0F24B5", "wsp_cl2": "#F93B47", "wsp_cl3": "#5BD262", "wsp_cl4": "#A401BA", "wsp_cl5": "#45650F", "wsp_cl6": "#1B3C01", "wsp_cl7": "#3D17C9", "wsp_cl8": "#22FEA4", "wsp_cl9": "#6F5B18", "wsp_cl10": "#C6B6E9", "wsp_cl11": "#F07AEE", "wsp_cl12": "#336387", "wsp_cl13": "#2D94E4", "wsp_cl14": "#D343E0", "wsp_cl15": "#6B9E4F", "wsp_cl16": "#7DE2DD");

@each $name, $color in $colors {
    .#{$name} {
    background-color: #{$color} !important;
  }
}

$categories:("category_cl1":"#005496", "category_cl2":"#ABE1FA", "category_cl3":"#FAA61A", "category_cl4":"#FFE293", "category_cl5":"#62136D", "category_cl6":"#C7A0CB", "category_cl7":"#79A342", "category_cl8":"#E8EEAE", "category_cl9":"#CB582C", "category_cl10":"#FDD1B0", "category_cl11":"#00707E", "category_cl12":"#92D6E3", "category_cl13":"#2D94E4", "category_cl14":"#D343E0", "category_cl15":"#6B9E4F", "category_cl16":"#7DE2DD");
@each $name, $color in $categories {
    .#{$name} {
    background-color: #{$color} !important;
  }
}
.input-adornment  input::-webkit-input-placeholder {
	color: #333333 !important;
}
.MuiButton-root {
    font-size: $font-body;
    padding: 12px 32px !important;
    text-transform: initial !important;
    color: #F9FBFC !important;
}
.MuiButton-label {
    font-weight: 300 !important;
}
.MuiTypography-root, .MuiTableContainer-root, .MuiTable-root, .MuiTableCell-root {
    font-family: $font-family-sans-serif !important;
}
.MuiFilledInput-root {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

    .MuiFilledInput-input {
        background-color: #FFFFFF;
        padding: 10.5px !important;
        border-radius: 8px !important;
        border-top-left-radius: none !important;
        border-top-right-radius: none !important;
    }
}
.MuiInputBase-root {
    font-family: $font-family-sans-serif !important;
    border: $border-input;
    font-size: $font-body;
    box-sizing: border-box;
    border-radius: 8px;

    .MuiInputBase-input {

    }
    .MuiInputBase-fullWidth {
        // margin-bottom: 16px;
    }
    .MuiInputBase-inputMultiline {
        padding: 0
    }
    .MuiInputBase-multiline {
        padding: 12px 16px;
        border-radius: 4px;
    }
}

.MuiSelect-root {
    background-color: #FFFFFF !important;
    /* /Primary/Dark/20 */
}
.MuiSelect-select {
    background-color: #FFFFFF;
    padding: 12px !important;
    // border: 1px solid #CED1DA !important;
    // box-sizing: border-box;
    border-radius: 8px !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}
.MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
    border-bottom: none !important;
}
.MuiInput-underline:after, .MuiInput-underline:before {
    border-bottom: none !important;
}
@for $i from 1 through 10 {
    .h-#{$i*8} {
        height: #{$i*8}px;
    }
    .w-#{$i*8} {
        width: #{$i*8}px;
    }
    .m-#{$i*8} {
        margin: #{$i*8}px;
    }
    .ml-#{$i*8} {
        margin-left: #{$i*8}px !important;
    }
    .mr-#{$i*8} {
        margin-right: #{$i*8}px !important;
    }
    .mt-#{$i*8} {
        margin-top: #{$i*8}px !important;
    }
    .mb-#{$i*8} {
        margin-bottom: #{$i*8}px !important;
    }

    .p-#{$i*8} {
        padding: #{$i*8}px;
    }
    .pl-#{$i*8} {
        padding-left: #{$i*8}px !important;
    }
    .pr-#{$i*8} {
        padding-right: #{$i*8}px !important;
    }
    .pt-#{$i*8} {
        padding-top: #{$i*8}px !important;
    }
    .pb-#{$i*8} {
        padding-bottom: #{$i*8}px !important;
    }
    .px-#{$i*8} {
        padding-left: #{$i*8}px;
        padding-right: #{$i*8}px;
    }
    .py-#{$i*8} {
        padding-bottom: #{$i*8}px;
        padding-top: #{$i*8}px;
    }
}
label {
    margin-bottom: 0;
    display: block;
}
.d-flex {
    display: flex;
}
.a-item-center {
    align-items: center;
}
.border-dotted {
    border: 1px dashed #CED1DA;
}

.template-page {
    width: 578px !important;
    height: 728px !important;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
    position: relative;
        background-color: $body-bg;
        height: 100%;
        padding: $distance*8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    // &-left {
        // &-title {
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: bold;
        //     font-size: 16px;
        //     line-height: 24px;
        //     color: #646465;
        //     position: absolute;
        //     top: 24px;
        // }
        .block-left {
            max-width: 149.84px;
        }

        .affiche {
            float: right;
        }
        .content{
            background-color: #FFF;
            position: relative;

            .t-category-right {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 0px 35px 50px;
                width: 185.64px;
                border-style: solid;
                transform: rotateZ(270deg);
                text-transform: uppercase;
                position: absolute;
                right: -76px;
                top: 76px;

                label {
                    position: absolute;
                    top: 10px;
                    right: 24px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
            .category {
                position: relative;
                padding-top: 24px;
                width: 149.84px;
                height: 241.5px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                .t-category {
                    position: absolute;
                    top: 44px;
                    left: -8px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    color: #000000;
                    transform: rotate(-90deg);
                }

                .b-time {
                    display: flex;
                    align-items: center;
                    float: right;
                    margin-top: 8px;
                }
                .time {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 25px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
                .duree {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 8px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
            }
            .d-lieux {
                .black {
                    width: 112.88px;
                    height: 35px;
                    background-color: #000000;
                    color: #FFF;
                    .t-above {
                        font-family: 'Oswald' !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 9px;
                    }
                    .t-under {
                        font-family: 'Oswald' !important;;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 7px;
                        line-height: 8px;
                    }
                }
                .white {
                    width: 112.88px;
                    height: 35px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 11px;
                    display: flex;
                    .bevel {
                        border-color: #005496 transparent #005496 transparent;
                        border-width: 0px 21px 35px 0px;
                        height: 0;
                        width: 43px;
                        border-style: solid;
                        color: #fff;
                    }
                    .number {
                        label {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            line-height: 10px;
                            &:first-child {
                                font-size: 10px;
                                font-weight: bold;
                            }
                            &:nth-child(2) {
                                font-size: 8px;
                                font-weight: normal;
                                letter-spacing: -0.01em;
                                text-transform: lowercase;
                                position: absolute;
                            }
                        }

                    }
                    .text {
                        span {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 8px;
                            line-height: 10px;
                        }
                    }
                }
                .photo-name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 6px;
                    line-height: 8px;
                    color: #000000;
                    padding-bottom: 5px;
                }
                .corner {
                    width: 50px;
                    border-style: solid;
                    border-color: #ABE1FA transparent #ABE1FA transparent;
                    border-width: 10px 0 0 8px;
                    float: right;
                }

            }
            .page-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 26px;
                letter-spacing: 0.03em;
                color: #005496;

            }
            .sub-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.03em;
                color: #005496;
                margin-top: 6px;
            }
            .p-item {
                line-height: 20px;
                .name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
                .value {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
            }
        }
        .d-bottom {
            display: flex;
            height: 68px;
            background-color: #FFFFFF;
            .i-logo {
                width: 42px;
                height: 42px;
            }
            .b-left {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 40px 68px 0px;
                width: 191.39px;
                border-style: solid;
                position: relative;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 9px;
                    &:first-child {
                        font-weight: bold;
                        text-align: right;
                        text-transform: uppercase;
                        margin-top: 10px;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        top: 20px;
                        right: 4px;
                    }
                    &:nth-child(3) {
                        position: absolute;
                        top: 30px;
                        right: -3px;
                    }
                    &:nth-child(4) {
                        position: absolute;
                        top: 40px;
                        right: -8px;
                    }
                }
                .t-page {
                    position: absolute;
                    top: 40px;
                    left: 12px;
                }
            }
            .b-right {
                position: relative;
                width: 188px;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 10px;
                    &:nth-child(2) {
                        font-weight: bold;
                        margin-left: 4px;
                    }
                    &:nth-child(3) {
                        margin-left: 8px;
                    }
                    &:nth-child(4) {
                        margin-left: 12px;
                    }

                }
                .t-page {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 7px;
                    line-height: 9px;
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                }
            }
        }
        .block-right {
            position: relative;

        }
    //}
    .f-right {
        float: right;
    }
    label {
        margin-bottom: 0;
        display: block;
    }
    .in-mini {
        width: 94px;
    }
    .in-unit {
        width: 308px
    }
    .t-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #0F24B5;

        img {
            width: 20px;
        }
    }
    .d-btn {
        display: flex;
        justify-content: space-evenly;
    }
    p {
        margin-bottom: 0;
    }
    .mt-6 {
        margin-top: 6px;
    }
}

input:-internal-autofill-selected {

    background-image: none !important;
    background-color: #FFF !important;
    color: $input-text-color !important;
}

iframe {
    top: 128px !important;
    left: 115px !important;
    //right: 500px !important;
    width: 67.8999% !important;
}

.MuiPickersModal-dialogRoot {
    .MuiButton-textPrimary {
        span {
            color: #0F24B5 !important;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400 !important;
        }

    }
}
.MuiPickersToolbar-toolbar {
    .MuiGrid-justify-content-xs-center {
        >div {
            &:first-child {
                .MuiTypography-h4 {
                    font-size: 17px !important;
                }
            }
            &:nth-child(2) {
                .MuiPickersToolbarButton-toolbarBtn {
                    max-width: 25px !important;
                }
            }
        }
    }
}
.date-field {
    .MuiOutlinedInput-root {
        border-radius: 8px;

        .MuiOutlinedInput-input {
            padding: 0 15px;
            height: 40px;
            color: #071332;
            font-size: 16px;
            line-height: 24px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
    }

}

.dropzone {
    border: dashed darkgrey thin;
    padding: 16px;
    border-radius: 8px;
}
.modal-container {
    z-index: 99999 !important;
}
.MuiAutocomplete-inputRoot {
    height: 40px;
    border-radius: 8px !important;
    border: none;
    input.MuiAutocomplete-input {
        padding-top: 0 !important;
    }
}