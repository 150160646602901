.element-editPage {
    &.editPage-object {
        display: block;
        position: relative;
        overflow-y: auto;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // overflow: scroll;
    // height: 100%;
    .icon-img {
        cursor: pointer;
    }
    .icon-disable {
        opacity: 0.3;
    }
    .page-wrapper {
        display: block !important;
        // width: 21cm !important;
        // height: 29.7cm;
        box-shadow: 0px 0px 4px #aaa;
        margin: 0 auto;
        position: relative;
        // top: 0;
        // border: solid;
        // margin-top: 10px;

        .block-wrapper {
            width: 100%;
            height: 100%;
            display: inline-block;
            position: absolute;
            z-index: 9;

            .react-contextmenu-wrapper {
                width: 100%;
                height: 100%;
            }

            .layer-menu-0 {
                width: 100%;
                height: 100%;
            }
        }
    }

    iframe {
        display: block !important;
        width: 100% !important;
        height: 100%;
    }

    .react-draggable {
        &:hover {
            // box-shadow: 0px 0px 4px #aaa;
        }
        .zoom-image {
            width: 100%;
            height: 100%;
            > div {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.react-draggable {

    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
    &.hover {
        box-shadow: 0 0 0 1px #0e20a3;
        > div:last-child div {
            border: 1px dotted #9FA7E1 !important;
        }
    }
    &.selected {
        box-shadow: 0 0 0 1px #0e20a3;
        > div:last-child div {
            border: 1px solid #9FA7E1 !important;
        }
    }
    > div:last-child {
        div {
            // background-color: red;
        }
        div:nth-child(1) {
            width: 10px !important;
            left: 50% !important;
            margin-left: -5px !important;
        }
        div:nth-child(2) {
            height: 10px !important;
            top: 50% !important;
            margin-top: -5px !important;
        }
        div:nth-child(3) {
            width: 10px !important;
            left: 50% !important;
            margin-left: -5px !important;
        }
        div:nth-child(4) {
            height: 10px !important;
            top: 50% !important;
            margin-top: -5px !important;
        }
        div:nth-child(5) {
            width: 10px !important;
            height: 10px !important;
            right: -5px !important;
            top: -5px !important;
        }
        div:nth-child(6) {
            width: 10px !important;
            height: 10px !important;
            bottom: -5px !important;
            right: -5px !important;
        }
        div:nth-child(7) {
            width: 10px !important;
            height: 10px !important;
            bottom: -5px !important;
            left: -5px !important;
        }
        div:nth-child(8) {
            width: 10px !important;
            height: 10px !important;
            top: -5px !important;
            left: -5px !important;
        }
    }
}

.block-unset {
    width: unset !important;
    height: unset !important;
}

.react-draggable-outside {
    width: 100%;
    height: 100%;
}

.menu-right-drag {
    overflow: visible !important;
}

#menu-right-page {
    position: relative;
}
#menu-right-page-drag {
    height: 100%;
    width: 100%;
    position: absolute;
}

#getHeightElement {
    z-index: 99;
}

.is-selected {
    button{
        color: #fff;
        font-weight: 500;
        background-color: #3f51b5;
    }
}

.none-selected {
    button{
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: 500;
        background-color: #fff !important;
    }
}