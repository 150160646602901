@import "./sass/app.scss";
.display-1 {
  text-transform: none;
}

.display-1-light {
  text-transform: none;
}

.display-2 {
  text-transform: none;
}

.display-2-light {
  text-transform: none;
}

.display-3 {
  text-transform: none;
}

.display-3-light {
  text-transform: none;
}