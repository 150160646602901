@import "../../sass/variables";

.page {
    width: 1156px !important;
    height: 728px !important;
    margin: auto;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
    &-left {
        position: relative;
        &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #646465;
            position: absolute;
            top: 24px;
        }
        .block-left {
            max-width: 149.84px;
        }
        background-color: $body-bg;
        height: 100%;
        padding: 32px 64px 0 64px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        .affiche {
            float: right;
        }
        .content{
            background-color: #FFF;
            position: relative;

            .t-category-right {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 0px 35px 50px;
                width: 185.64px;
                border-style: solid;
                transform: rotateZ(270deg);
                text-transform: uppercase;
                position: absolute;
                right: -76px;
                top: 76px;

                label {
                    position: absolute;
                    top: 10px;
                    right: 24px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
            .category {
                position: relative;
                padding-top: 24px;
                width: 149.84px;
                height: 241.5px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                .t-category {
                    position: absolute;
                    top: 44px;
                    left: -8px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    color: #000000;
                    transform: rotate(-90deg);
                }

                .b-time {
                    display: flex;
                    align-items: center;
                    float: right;
                    margin-top: 8px;
                }
                .time {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 25px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
                .duree {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 8px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
            }
            .d-lieux {
                .black {
                    width: 112.88px;
                    height: 35px;
                    background-color: #000000;
                    color: #FFF;
                    .t-above {
                        font-family: 'Oswald' !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 9px;
                    }
                    .t-under {
                        font-family: 'Oswald' !important;;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 7px;
                        line-height: 8px;
                    }
                }
                .white {
                    width: 112.88px;
                    height: 35px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 11px;
                    display: flex;
                    .bevel {
                        border-color: #005496 transparent #005496 transparent;
                        border-width: 0px 21px 35px 0px;
                        height: 0;
                        width: 43px;
                        border-style: solid;
                        color: #fff;
                    }
                    .number {
                        label {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            line-height: 10px;
                            &:first-child {
                                font-size: 10px;
                                font-weight: bold;
                            }
                            &:nth-child(2) {
                                font-size: 8px;
                                font-weight: normal;
                                letter-spacing: -0.01em;
                                text-transform: lowercase;
                                position: absolute;
                            }
                        }

                    }
                    .text {
                        span {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 8px;
                            line-height: 10px;
                        }
                    }
                }
                .photo-name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 6px;
                    line-height: 8px;
                    color: #000000;
                    padding-bottom: 5px;
                }
                .corner {
                    width: 50px;
                    border-style: solid;
                    border-color: #ABE1FA transparent #ABE1FA transparent;
                    border-width: 10px 0 0 8px;
                    float: right;
                }

            }
            .page-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 26px;
                letter-spacing: 0.03em;
                color: #005496;

            }
            .sub-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.03em;
                color: #005496;
                margin-top: 6px;
            }
            .p-item {
                line-height: 20px;
                .name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
                .value {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
            }
        }
        .d-bottom {
            display: flex;
            height: 68px;
            background-color: #FFFFFF;
            .i-logo {
                width: 42px;
                height: 42px;
            }
            .b-left {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 40px 68px 0px;
                width: 191.39px;
                border-style: solid;
                position: relative;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 9px;
                    &:first-child {
                        font-weight: bold;
                        text-align: right;
                        text-transform: uppercase;
                        margin-top: 10px;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        top: 20px;
                        right: 4px;
                    }
                    &:nth-child(3) {
                        position: absolute;
                        top: 30px;
                        right: -3px;
                    }
                    &:nth-child(4) {
                        position: absolute;
                        top: 40px;
                        right: -8px;
                    }
                }
                .t-page {
                    position: absolute;
                    top: 40px;
                    left: 12px;
                }
            }
            .b-right {
                position: relative;
                width: 188px;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 10px;
                    &:nth-child(2) {
                        font-weight: bold;
                        margin-left: 4px;
                    }
                    &:nth-child(3) {
                        margin-left: 8px;
                    }
                    &:nth-child(4) {
                        margin-left: 12px;
                    }

                }
                .t-page {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 7px;
                    line-height: 9px;
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                }
            }
        }
        .block-right {
            position: relative;

        }
    }
    &-right {
        background-color: #FFF;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        position: relative;
        padding: 32px 24px;
        overflow: auto;
        .d-revenir {
            display: flex;
            align-items: center;
            .btn-arrow {
                width: 48px;
                height: 48px;
                border: $border-input;
                box-sizing: border-box;
                border-radius: 8px;
                align-items: center;
                justify-content: center;
                display: flex;
                img {
                    transform: rotate(90deg);
                }
            }
            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .txt-label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: #646465;
            margin-bottom: 8px;
            img {
                width: 15px;
                height: 15px;
            }
        }
        .title-page-r {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #0A1B48;
        }
    }
    .f-right {
        float: right;
    }
    .in-mini {
        width: 94px;
    }
    .in-unit {
        width: 308px
    }
    .t-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #0F24B5;

        img {
            width: 20px;
        }
    }
    .d-btn {
        display: flex;
        justify-content: space-evenly;
    }
    .mt-6 {
        margin-top: 6px;
    }
}
.projectSlide{
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .swiper-pagination-bullet{
        width: 4px;
        height: 4px;
        background: #9FA7E1;
    }
    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background: #09166D;
    }
    img{
        width: 100%;
		height: 623px;
		object-fit: cover;
    }
}
.addProjectBtnBack{
    min-width: 48px !important;
    padding: 0 !important;
}